import React from "react";
import TablePagination from "@mui/material/TablePagination";

const PaginationControl = ({ items, page, rowsPerPage, setPage, setRowsPerPage }) => (
  <TablePagination
    rowsPerPageOptions={[5, 10, 25]}
    component="div"
    count={items.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={(e, newPage) => setPage(newPage)}
    onRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
  />
);

export default PaginationControl;
