import React, { useState, useCallback } from "react";
import { Buffer } from "buffer";

const ItemImage = ({ isEditing, editedItem, item, setEditedItem }) => {
  const [previewImage] = useState(null);

  // Function to convert image data to a usable base64 source
  const getItemImageSrc = useCallback(() => {
    if (item.item_image && item.item_image.data) {
      return `data:${item.mimeType};base64,${Buffer.from(item.item_image.data).toString("base64")}`;
    }
    return item.item_image;
  }, [item.item_image, item.mimeType]); // added dependencies

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      // If a new image is selected, update the editedItem
      setEditedItem({
        ...editedItem,
        item_image: e.target.files[0], // Set new image
      });
    }
  };

  return isEditing ? (
    <>
      <input
        type="file"
        name="item_image"
        onChange={handleImageChange}
      />
      {previewImage && (
        <img
          src={previewImage}
          alt={editedItem.item_name || "Uploaded Image"}
          width="50"
          height="50"
        />
      )}
    </>
  ) : item.item_image ? (
    <img
      src={getItemImageSrc()}
      alt={item.item_name || "Item Image"}
      width="50"
      height="50"
    />
  ) : (
    "No Image"
  );
};

export default ItemImage;
