import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const ItemNameSelect = ({ itemName, setItemName, availableItems }) => {
  // Get unique item names using a Set
  const uniqueItems = availableItems?.allItemsGot
    ? Array.from(new Set(availableItems.allItemsGot.map((item) => item.item_name)))
    : [];

  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel id="item-name-label">Item Name</InputLabel>
      <Select
        labelId="item-name-label"
        value={itemName}
        label="Item Name"
        onChange={(e) => setItemName(e.target.value)}
        defaultValue=""
      >
        {uniqueItems.length > 0 ? (
          uniqueItems.map((name, index) => (
            <MenuItem key={index} value={name}>
              {name}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            No items available
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default ItemNameSelect;
