import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import axios from "axios";
import ItemNameInput from "./itemNameInput";
import CategoryRadioGroup from "./categorySelect";

// const url = "http://localhost:8001/bbgapi/item"
const url = "https://www.bbgrestaurant.com/bbgapi/item"

const FormContainer = ({ updateFormData }) => {
  const [item_name, setItemName] = useState("");
  const [category, setCategory] = useState("");
  const [existingItems, setExistingItems] = useState([]);
  const [, setLoading] = useState(true);


  console.log("items url remote", url);
  // Fetch items when the component mounts
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(url);
        console.log("items remote response", response);
        console.log("Fetched items:", response.data);
        setExistingItems(response.data.allItemsGot); // Ensure this is the correct key in your backend response
        setLoading(false);
      } catch (error) {
        console.error("Error fetching items:", error);
        setLoading(false);
      }
    };

    fetchItems();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // If no category is selected, set it to 0
    const item_category = category === "" ? "0" : category === "veg" ? "veg" : "non-veg";
  
    // Ensure existingItems is an array
    if (!Array.isArray(existingItems)) {
      alert("Available items data is not loaded properly.");
      return;
    }
  
    console.log("Existing items:", existingItems);
  
    // Normalize item_category by removing any extra quotes
    const normalizedCategory = item_category.toLowerCase().replace(/['"]+/g, '');
  
    // Check if the item already exists
    const itemExists = existingItems.some((item) => {
      // Ensure item_name and item_category are defined before calling toLowerCase
      return (
        item.item_name?.toLowerCase() === item_name.toLowerCase() &&
        item.item_category?.toLowerCase().replace(/['"]+/g, '') === normalizedCategory
      );
    });
  
    console.log("Item exists:", itemExists);
  
    if (itemExists) {
      alert("Item with this name and category already exists.");
      return;
    }
  
    const data = { item_name, item_category };
  
    // API call logic to submit the new item
    try {
      const response = await axios.post(
        url,
        data
      );
      console.log("Submitted response:", response.data);
  
      // Add the new item to the existing items list
      setExistingItems([...existingItems, response.data]);
    } catch (err) {
      console.error("Error submitting the item:", err);
    }
  
    // Reset the form
    setItemName("");
    setCategory("");
  };
  

  return (
    <Box
      component="form"
      method="post"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 400,
        margin: 10,
        padding: 5,
        border: "2px solid black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <Typography sx={{ fontWeight: "bold", textTransform: "uppercase" }}>
        Item Register
      </Typography>

      <ItemNameInput itemName={item_name} setItemName={setItemName} />
      <CategoryRadioGroup category={category} setCategory={setCategory} />

      <Button type="submit" variant="contained" color="primary" fullWidth>
        Submit
      </Button>
    </Box>
  );
};

export default FormContainer;
