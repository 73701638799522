import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box } from "@mui/material";
import FormContainer from "./formContainer";

const url = 'https://www.bbgrestaurant.com/bbgapi'

const ParentComponent = ({ showAddForm }) => {
  const [formData, setFormData] = useState({
    item_name: "",
    item_category: { veg: 0, nonVeg: 0 },
  });

  const [submittedItems, setSubmittedItems] = useState([]);
  const [availableItems, setAvailableItems] = useState([]); // Initialized as empty array

  const [,setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(`${url}/item`);
        console.log("data ",response);
        setAvailableItems(response.data); // Set items once fetched
        setLoading(false); // Data is now loaded
      } catch (error) {
        console.error("Error fetching items:", error);
        setLoading(false);
      }
    };
  
    fetchItems();
  }, []);
  

  const updateFormData = (newData) => {
    setFormData(newData);
  };

  const handleItemSubmit = (item) => {
    // Check if the item is already in the submittedItems list
    const isItemAlreadySubmitted = submittedItems.some(
      (submittedItem) => submittedItem.item_name === item.item_name
    );

    // Add the item only if it is not already in the list (i.e., unique item)
    if (!isItemAlreadySubmitted) {
      setSubmittedItems([...submittedItems, item]);
    }
  };

  return (
    <Box>
      <FormContainer
        showAddForm={showAddForm}
        updateFormData={updateFormData}
        handleItemSubmit={handleItemSubmit}
        formData={formData}
        availableItems={availableItems}
      />
      
    </Box>
  );
};

export default ParentComponent;