import React, { useState } from "react";
import { TextField, IconButton, Chip, Box } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const IngredientInput = ({ ingredients, setIngredients }) => {
  const [ingredientInput, setIngredientInput] = useState("");

  const handleAddIngredient = () => {
    const trimmedIngredient = ingredientInput.trim();
    
    // Ensure input is not empty and not a duplicate
    if (trimmedIngredient && !ingredients.includes(trimmedIngredient)) {
      setIngredients([...ingredients, trimmedIngredient]);
      setIngredientInput(""); // Clear input field after adding
    }
  };

  const handleDeleteIngredient = (ingredientToDelete) => {
    setIngredients(ingredients.filter((ingredient) => ingredient !== ingredientToDelete));
  };

  // Allow adding ingredient by pressing Enter key
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      handleAddIngredient();
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <TextField
        label="Add Ingredient"
        value={ingredientInput}
        onChange={(e) => setIngredientInput(e.target.value)}
        onKeyPress={handleKeyPress} // Add keypress handler
        fullWidth
      />
      <IconButton
        onClick={handleAddIngredient}
        color="primary"
        sx={{ mt: 1 }}
        disabled={!ingredientInput.trim()} // Disable button if input is empty
      >
        <AddCircleIcon />
      </IconButton>
      <Box sx={{ mt: 2 }}>
        {ingredients.map((ingredient, index) => (
          <Chip
            key={index}
            label={ingredient}
            onDelete={() => handleDeleteIngredient(ingredient)}
            sx={{ marginRight: 1, marginBottom: 1 }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default IngredientInput;
