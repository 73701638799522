import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
} from "@mui/material";
import PaginationControl from "./pagination"; // Ensure you have this component ready
import axios from "axios";

const PaginatedOrderTable = () => {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Fetch orders data from API when the component mounts
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          "https://www.bbgrestaurant.com/bbgapi/joinOrder"
        );
        console.log("API response", response.data.data);

        // Check if the response data is structured correctly
        if (
          Array.isArray(response.data.data) &&
          response.data.data.length > 0
        ) {
          // Extract the first array (if the API returns nested arrays)
          setOrders(response.data.data[0]);
        } else {
          setOrders([]); // Fallback to an empty array if the structure is not as expected
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
        setOrders([]); // Fallback to an empty array on error
      }
    };

    fetchOrders();
  }, []);

  return (
    <Box sx={{ width: "100%", overflowX: "auto", p: 2 }}>
      <Paper>
        <TableContainer sx={{ overflowX: "auto" }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ fontWeight: "bold" }}>
                <TableCell>S.N</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Customer Address</TableCell>
                <TableCell>Customer Phone</TableCell>
                <TableCell>Customer Email</TableCell>
                <TableCell>Order Instructions</TableCell>
                <TableCell>Address Instructions</TableCell>
                <TableCell>Order Items</TableCell>
                <TableCell>Order Quantity</TableCell>
                <TableCell>Total Price</TableCell>
                <TableCell>Order CID</TableCell>
                <TableCell>Table No.</TableCell>
                <TableCell>Tracking ID</TableCell>
                <TableCell>Track Status</TableCell>
                <TableCell>Payment Mode</TableCell>
                <TableCell>Payment Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={15} align="center">
                    No orders found.
                  </TableCell>
                </TableRow>
              ) : (
                orders
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((order, index) => (
                    <TableRow key={order.order_cid || index}>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        {page * rowsPerPage + index + 1}{" "}
                        {/* Serial number calculation */}
                      </TableCell>
                      <TableCell>
                        {order.customer_detail
                          ? (() => {
                              try {
                                const customer = JSON.parse(
                                  order.customer_detail
                                );
                                return customer.name || "No name provided";
                              } catch (e) {
                                console.error(
                                  "Invalid JSON in customer_detail:",
                                  e
                                );
                                return "Invalid data";
                              }
                            })()
                          : "No customer detail"}
                      </TableCell>
                      <TableCell>
                        {order.customer_detail
                          ? (() => {
                              try {
                                const customer = JSON.parse(
                                  order.customer_detail
                                );
                                return customer.address || "No address provided";
                              } catch (e) {
                                console.error(
                                  "Invalid JSON in customer_detail:",
                                  e
                                );
                                return "Invalid data";
                              }
                            })()
                          : "No customer detail"}
                      </TableCell>
                      <TableCell>
                        {order.customer_detail
                          ? (() => {
                              try {
                                const customer = JSON.parse(
                                  order.customer_detail
                                );
                                return customer.phone || "No phone provided";
                              } catch (e) {
                                console.error(
                                  "Invalid JSON in customer_detail:",
                                  e
                                );
                                return "Invalid data";
                              }
                            })()
                          : "No customer detail"}
                      </TableCell>
                      <TableCell>
                        {order.customer_detail
                          ? (() => {
                              try {
                                const customer = JSON.parse(
                                  order.customer_detail
                                );
                                return customer.email || "No email provided";
                              } catch (e) {
                                console.error(
                                  "Invalid JSON in customer_detail:",
                                  e
                                );
                                return "Invalid data";
                              }
                            })()
                          : "No customer detail"}
                      </TableCell>
                      <TableCell>
                        {order.order_instructions || "No instructions"}
                      </TableCell>
                      <TableCell>
                        {order.order_address_instruction ||
                          "No address instructions"}
                      </TableCell>
                      <TableCell>
                        {order.order_items
                          ? (() => {
                              try {
                                const items = JSON.parse(order.order_items);
                                return items.map((item, idx) => (
                                  <div key={idx}>
                                    {item.name} x {item.quantity}
                                  </div>
                                ));
                              } catch (e) {
                                console.error(
                                  "Invalid JSON in order_items:",
                                  e
                                );
                                return "Invalid data";
                              }
                            })()
                          : "No order items"}
                      </TableCell>
                      <TableCell>{order.order_quantity || "N/A"}</TableCell>
                      <TableCell>{order.total_price || "N/A"}</TableCell>
                      <TableCell>{order.order_cid || "N/A"}</TableCell>
                      <TableCell>{order.table_no || "N/A"}</TableCell>
                      <TableCell>{order.tracking_id || "N/A"}</TableCell>
                      <TableCell>{order.track_status || "N/A"}</TableCell>
                      <TableCell>{order.payment_mode || "N/A"}</TableCell>
                      <TableCell>{order.payment_status || "N/A"}</TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationControl
          items={orders}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default PaginatedOrderTable;
