import React, { useState, useEffect } from "react";
import { TextField, Checkbox } from "@mui/material";

const StatusField = ({
  isEditing,
  statusField,
  item,
  editedItem,
  setEditedItem,
}) => {
  // Parse the current status field data
  const status = JSON.parse(item[statusField] || "[]")[0] || {
    status: false,
    "start time": "N/A",
    "end time": "N/A",
  };

  // Local state to manage checkbox status
  const [checkboxStatus, setCheckboxStatus] = useState(status.status);

  // Update the parent state with the new status value
  const handleStatusChange = (field, value) => {
    const currentStatus = JSON.parse(editedItem[statusField] || "{}");
    setEditedItem((prevItem) => ({
      ...prevItem,
      [statusField]: JSON.stringify([{ ...currentStatus, [field]: value }]),
    }));
  };

  // Effect to synchronize the local checkbox status with the editedItem when it changes
  useEffect(() => {
    setCheckboxStatus(status.status);
  }, [status.status]);

  return isEditing ? (
    <>
      <Checkbox
        checked={checkboxStatus}
        onChange={(e) => {
          setCheckboxStatus(e.target.checked); // Change checkbox color immediately
          handleStatusChange("status", e.target.checked); // Update the parent state with the new status
        }}
        sx={{
          color: "blue",
          "&.Mui-checked": {
            color: "blue",
          },
          "&.MuiCheckbox-root": {
            transition: "color 0.3s", // Smooth color transition
          },
        }}
      />
      <TextField
        value={status["start time"]}
        onChange={(e) => handleStatusChange("start time", e.target.value)}
        label="Start Time"
        size="small"
        fullWidth
        multiline
        variant="outlined"
        sx={{
          width: "100%",
          minWidth: "250px",
          backgroundColor: "rgba(173, 216, 230, 0.3)",
          "&:focus": {
            backgroundColor: "white",
          },
        }}
      />
      <TextField
        value={status["end time"]}
        onChange={(e) => handleStatusChange("end time", e.target.value)}
        label="End Time"
        size="small"
        fullWidth
        multiline
        variant="outlined"
        sx={{
          width: "100%",
          minWidth: "250px",
          backgroundColor: "rgba(173, 216, 230, 0.3)",
          "&:focus": {
            backgroundColor: "white",
          },
        }}
      />
    </>
  ) : (
    `${status.status ? "True" : "False"} / ${status["start time"]} : ${
      status["end time"]
    }`
  );
};

export default StatusField;
