import React from "react";
import { Box, Typography } from "@mui/material";
import OrderDetail from "../orderTable/orderDataTable";

const OrderManagement = () => {
  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
        Order Detail
      </Typography>
      <OrderDetail />
    </Box>
  );
};

export default OrderManagement;
