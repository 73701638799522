import { TextField, Box, Typography, Button } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";

const ImageUpload = ({ setImage }) => {
  const [preview, setPreview] = useState(null); // State to store the image preview

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check if file type is an image
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validTypes.includes(file.type)) {
        toast.error("Invalid file type. Please select an image file (JPEG/PNG).");
        return;
      }

      // Check for file size (1MB max)
      if (file.size > 1 * 1024 * 1024) {
        toast.error("File size exceeds 1MB. Please select a smaller file.");
        return;
      }

      // Update image state and preview
      setImage(file);
      setPreview(URL.createObjectURL(file)); // Create a preview URL for the image
    }
  };

  const handleRemoveImage = () => {
    setImage(null); // Reset the image state
    setPreview(null); // Reset the preview
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Typography sx={{ mb: 1 }}>Upload Item Image (Max 1MB)</Typography>
      <TextField
        type="file"
        inputProps={{ accept: "image/*" }} // Accept only image files
        onChange={handleImageChange}
        fullWidth
      />
      {preview && (
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <Typography>Image Preview:</Typography>
          <img
            src={preview}
            alt="Preview"
            style={{ width: "100%", maxHeight: "200px", objectFit: "contain" }}
          />
          <Button
            onClick={handleRemoveImage}
            color="secondary"
            sx={{ mt: 1 }}
            variant="contained"
          >
            Remove Image
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ImageUpload;
