// paginationControl.jsx

import React from "react";
import { TablePagination } from "@mui/material";

const Pagination = ({ items, page, rowsPerPage, setPage, setRowsPerPage }) => {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page whenever rows per page changes
  };

  return (
    <TablePagination
      component="div"
      count={items.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage="Rows per page"
      rowsPerPageOptions={[5, 10, 25]}
      showFirstButton
      showLastButton
    />
  );
};

export default Pagination;
