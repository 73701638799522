import React from "react";
import ItemForm from "../ItemRegisterForm/itemForm";
import ItemAddForm from "../ItemAdd/itemAddMain";

const FormContainer = ({ showAddForm, updateFormData, handleItemSubmit, formData, availableItems }) => {
  return (
    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      {!showAddForm ? (
        <ItemForm updateFormData={updateFormData} />
      ) : (
        <ItemAddForm
          formData={formData}
          onItemSubmit={handleItemSubmit}
          availableItems={availableItems}
        />
      )}
    </div>
  );
};

export default FormContainer;
