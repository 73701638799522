import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const CategorySelect = ({ category, setCategory }) => {
  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel id="category-label">Category</InputLabel>
      <Select
        labelId="category-label"
        value={category}
        label="Category"
        onChange={(e) => setCategory(e.target.value)}
        defaultValue=""
      >
        <MenuItem value="veg">Veg</MenuItem>
        <MenuItem value="non-veg">Non-Veg</MenuItem>
      </Select>
    </FormControl>
  );
};

export default CategorySelect;
