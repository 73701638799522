import React from "react";
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";

const CategoryRadioGroup = ({ category, setCategory }) => {
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  return (
    <FormControl component="fieldset" sx={{ mb: 2 }}>
      <FormLabel component="legend">Category</FormLabel>
      <RadioGroup value={category} onChange={handleCategoryChange} row>
        <FormControlLabel value="veg" control={<Radio />} label="Veg" />
        <FormControlLabel value="non-veg" control={<Radio />} label="Non-Veg" />
      </RadioGroup>
    </FormControl>
  );
};

export default CategoryRadioGroup;
