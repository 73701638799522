import React from "react";
import { TextField, Box } from "@mui/material";

const DescriptionUpload = ({ description, setDescription }) => {
  // Handle changes to the description input
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <TextField
        type="text"
        fullWidth
        label="Description"
        value={description}
        onChange={handleDescriptionChange}
        multiline // Enable multiline input
        minRows={3} // Minimum number of rows to show
        maxRows={6} // Optional: Max number of rows to grow to
      />
    </Box>
  );
};

export default DescriptionUpload;
