import React, { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
} from "@mui/material";
import EditableTableRow from "./editableTableRow";
import PaginationControl from "./paginationControl";
import axios from "axios";

const PaginatedItemTable = ({ submittedItems = [], onUpdate = () => {} }) => {
  const [items, setItems] = useState(
    Array.isArray(submittedItems[0]) ? submittedItems[0] : []
  );
  const [editIndex, setEditIndex] = useState(-1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editedItem, setEditedItem] = useState(null);

  const deleteItemFromDatabase = async (sid) => {
    try {
      const response = await axios.delete(
        `https://www.bbgrestaurant.com/bbgapi/joinData/${sid}`
      );
      if (response.status !== 200) {
        throw new Error("Failed to delete item");
      }
      setItems(items.filter((item) => item.sid !== sid));
      alert("Item deleted successfully");
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const updateItemInDatabase = async (sid, updatedItem) => {
    try {
      const response = await axios.patch(
        `https://www.bbgrestaurant.com/bbgapi/joinData/${sid}`,
        updatedItem,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status !== 200) {
        throw new Error("Failed to update item");
      }
      const updatedItems = items.map((item) =>
        item.sid === sid ? { ...item, ...updatedItem } : item
      );
      setItems(updatedItems);
      alert("Item updated successfully");
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  return (
    <Box sx={{ width: "100%", p: 2, overflowX: "auto" }}>
      <Paper>
        <TableContainer sx={{ width:'100%'}}>
          <Table
            stickyHeader
            aria-label="paginated table"
            sx={{ minWidth: 1000 }} // Minimum width to enable horizontal scroll if content exceeds this width
          >
            <TableHead>
              <TableRow>
                <TableCell>Serial No.</TableCell>
                <TableCell>Item Name</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Subcategory</TableCell>
                <TableCell>Item Type</TableCell>
                <TableCell>Item Image</TableCell>
                <TableCell>Item Description</TableCell>
                <TableCell>Item Ingredients</TableCell>
                <TableCell>Item Price</TableCell>
                <TableCell>Popular</TableCell>
                <TableCell>Special</TableCell>
                <TableCell>Offer Festival</TableCell>
                <TableCell>Instant Offer</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={13} align="center">
                    No items found.
                  </TableCell>
                </TableRow>
              ) : (
                items
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <EditableTableRow
                      key={item.sid}
                      index={index}
                      item={item}
                      isEditing={editIndex === index}
                      editedItem={editedItem}
                      setEditIndex={setEditIndex}
                      setEditedItem={setEditedItem}
                      items={items}
                      setItems={setItems}
                      deleteItem={deleteItemFromDatabase}
                      updateItem={updateItemInDatabase}
                    />
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationControl
          items={items}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default PaginatedItemTable;
