import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

const SubcategorySelect = ({ category, subcategory, setSubcategory }) => {
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [customSubcategory, setCustomSubcategory] = useState(""); // To store custom subcategory input

  useEffect(() => {
    const options =
      category === "veg"
        ? ["paneer", "veggies", "mushroom"]
        : category === "non-veg"
        ? ["buff", "chicken", "mutton"]
        : [];

    setSubcategoryOptions(options);
    setSubcategory(""); // Reset subcategory when category changes
  }, [category, setSubcategory]);

  const handleSubcategoryChange = (e) => {
    const value = e.target.value;
    if (value === "other") {
      setSubcategory(customSubcategory); // Set the custom input as the subcategory when 'Other' is selected
    } else {
      setSubcategory(value); // Set the selected option
    }
  };

  const handleCustomInputChange = (e) => {
    const value = e.target.value;
    setCustomSubcategory(value);
    setSubcategory(value); // Update the subcategory state with custom input
  };

  return (
    <>
      {/* Subcategory Dropdown, if a category is selected */}
      {category && (
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="subcategory-label">Subcategory</InputLabel>
          <Select
            labelId="subcategory-label"
            value={subcategory}
            label="Subcategory"
            onChange={handleSubcategoryChange}
            defaultValue=""
          >
            {subcategoryOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
      )}

      {/* Custom Subcategory Input always available */}
      <TextField
        fullWidth
        label="Custom Subcategory"
        value={customSubcategory || subcategory}
        onChange={handleCustomInputChange}
        placeholder="Enter custom subcategory"
        sx={{ mt: category ? 2 : 0 }} // Add margin only if dropdown is visible
      />
    </>
  );
};

export default SubcategorySelect;
