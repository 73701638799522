import React from "react";
import { TextField } from "@mui/material";

const ItemNameInput = ({ itemName, setItemName }) => {
  const handleItemNameChange = (e) => {
    setItemName(e.target.value);
  };

  return (
    <TextField
      label="Item Name"
      variant="outlined"
      fullWidth
      value={itemName}
      onChange={handleItemNameChange}
      sx={{ mb: 2 }}
    />
  );
};

export default ItemNameInput;
