import React from "react";
import { TableRow, TableCell, TextField, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ItemImage from "./itemImage";
import StatusField from "./itemStatus";

const EditableTableRow = ({
  index,
  item,
  isEditing,
  editedItem,
  setEditIndex,
  setEditedItem,
  deleteItem, // Receive deleteItem as a prop
  updateItem, // Receive updateItem as a prop
}) => {
  const handleFieldChange = (field, value) => {
    setEditedItem((prevItem) => ({
      ...prevItem,
      [field]: value,
      item_image: prevItem.item_image || item.item_image, // Retain existing image
    }));
  };

  const handleEditClick = () => {
    setEditIndex(index);
    setEditedItem({
      ...item,
      item_image: item.item_image || editedItem?.item_image, // Ensure image is carried over
    });
  };

  const handleSaveClick = async () => {
    try {
      await updateItem(item.sid, editedItem); // Send patch request to update the item in the database
      setEditIndex(-1);
      setEditedItem(null);
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const handleCancelClick = () => {
    setEditIndex(-1);
    setEditedItem(null);
  };

  const handleDeleteClick = () => {
    deleteItem(item.sid); // Use sid for deletion
  };

  return (
    <TableRow
      sx={{
        "&:hover": {
          backgroundColor: "rgba(0, 0, 255, 0.05)",
        },
        "& .MuiTableCell-root": {
          position: "relative",
          maxWidth: isEditing ? "none" : "150px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        "& .MuiTableCell-root:hover": {
          maxWidth: "none",
          overflow: "visible",
          zIndex: 1,
        },
      }}
    >
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        {isEditing ? (
          <TextField
            value={editedItem.item_name || ""}
            onChange={(e) => handleFieldChange("item_name", e.target.value)}
            fullWidth
            multiline
            variant="outlined"
            sx={{
              width: "100%",
              minWidth: "250px",
              backgroundColor: "rgba(173, 216, 230, 0.3)",
              "&:focus": {
                backgroundColor: "white",
              },
            }}
          />
        ) : (
          item.item_name || "N/A"
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <TextField
            value={editedItem.item_category || ""}
            onChange={(e) => handleFieldChange("item_category", e.target.value)}
            fullWidth
            multiline
            variant="outlined"
            sx={{
              width: "100%",
              minWidth: "250px",
              backgroundColor: "rgba(173, 216, 230, 0.3)",
              "&:focus": {
                backgroundColor: "white",
              },
            }}
          />
        ) : (
          item.item_category || "N/A"
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <TextField
            value={editedItem.item_subcategory || ""}
            onChange={(e) =>
              handleFieldChange("item_subcategory", e.target.value)
            }
            fullWidth
            multiline
            variant="outlined"
            sx={{
              width: "100%",
              minWidth: "250px",
              backgroundColor: "rgba(173, 216, 230, 0.3)",
              "&:focus": {
                backgroundColor: "white",
              },
            }}
          />
        ) : (
          item.item_subcategory || "N/A"
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <TextField
            value={editedItem.item_type || ""}
            onChange={(e) => handleFieldChange("item_type", e.target.value)}
            fullWidth
            multiline
            variant="outlined"
            sx={{
              width: "100%",
              minWidth: "250px",
              backgroundColor: "rgba(173, 216, 230, 0.3)",
              "&:focus": {
                backgroundColor: "white",
              },
            }}
          />
        ) : (
          item.item_type || "N/A"
        )}
      </TableCell>
      <TableCell>
        <ItemImage
          isEditing={isEditing}
          editedItem={editedItem}
          item={item}
          setEditedItem={setEditedItem}
        />
      </TableCell>
      <TableCell>
        {isEditing ? (
          <TextField
            value={editedItem.item_description || ""}
            onChange={(e) =>
              handleFieldChange("item_description", e.target.value)
            }
            fullWidth
            multiline
            variant="outlined"
            sx={{
              width: "100%",
              minWidth: "250px",
              backgroundColor: "rgba(173, 216, 230, 0.3)",
              "&:focus": {
                backgroundColor: "white",
              },
            }}
          />
        ) : (
          item.item_description || "N/A"
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <TextField
            value={editedItem.item_ingredients || ""}
            onChange={(e) =>
              handleFieldChange("item_ingredients", e.target.value)
            }
            fullWidth
            multiline
            variant="outlined"
            sx={{
              width: "100%",
              minWidth: "250px",
              backgroundColor: "rgba(173, 216, 230, 0.3)",
              "&:focus": {
                backgroundColor: "white",
              },
            }}
          />
        ) : (
          item.item_ingredients || "N/A"
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <TextField
            value={editedItem.item_price || ""}
            onChange={(e) => handleFieldChange("item_price", e.target.value)}
            fullWidth
            multiline
            variant="outlined"
            sx={{
              width: "100%",
              minWidth: "250px",
              backgroundColor: "rgba(173, 216, 230, 0.3)",
              "&:focus": {
                backgroundColor: "white",
              },
            }}
          />
        ) : (
          item.item_price || "N/A"
        )}
      </TableCell>
      <TableCell>
        <StatusField
          isEditing={isEditing}
          statusField="item_popular"
          item={item}
          editedItem={editedItem}
          setEditedItem={setEditedItem}
        />
      </TableCell>
      <TableCell>
        <StatusField
          isEditing={isEditing}
          statusField="item_special"
          item={item}
          editedItem={editedItem}
          setEditedItem={setEditedItem}
        />
      </TableCell>
      <TableCell>
        <StatusField
          isEditing={isEditing}
          statusField="item_offer_festival"
          item={item}
          editedItem={editedItem}
          setEditedItem={setEditedItem}
        />
      </TableCell>
      <TableCell>
        <StatusField
          isEditing={isEditing}
          statusField="item_instant_offer"
          item={item}
          editedItem={editedItem}
          setEditedItem={setEditedItem}
        />
      </TableCell>
      <TableCell>
        {isEditing ? (
          <>
            <IconButton onClick={handleSaveClick}>
              <SaveIcon />
            </IconButton>
            <IconButton onClick={handleCancelClick}>
              <CancelIcon />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleDeleteClick}>
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default EditableTableRow;
