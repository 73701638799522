import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for Toast notifications
import './App.css';
import PermanentDrawerLeft from './components/drawer/drawer'; 
// import AuthForm from './forms/signup_form/signup_form';

function App() {
  return (
    <Router>
      {/* ToastContainer to display toast notifications */}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <Routes>
          {/* <Route path="/" element={<AuthForm />} /> */}
          <Route path="/" element={<PermanentDrawerLeft />} />
      </Routes>
    </Router>
  );
}

export default App;
