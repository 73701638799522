import React, { useState } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Link,
  Avatar,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const url = "https://www.bbgrestaurant.com/bbgapi";

const AuthForm = () => {
  const [formData, setFormData] = useState({
    admin_contact: "",
    admin_password: "",
    otp: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateContact = (admin_contact) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;
    if (!emailRegex.test(admin_contact) && !phoneRegex.test(admin_contact)) {
      toast.error(
        "Invalid contact: Enter a valid email or 10-digit phone number."
      );
      return false;
    }
    return true;
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!validateContact(formData.admin_contact)) return;

    const payload = {
      admin_contact: formData.admin_contact,
      admin_password: formData.admin_password,
    };

    try {
      const response = await axios.post(`${url}/admin/login`, payload);
      console.log(response);
      toast.success("Login successful.");
      navigate("/"); // Redirect to the home page
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong.");
    }
  };

  const handleOtpSend = async (e) => {
    e.preventDefault();
    if (!validateContact(formData.admin_contact)) return;

    try {
      const otp = Math.floor(100000 + Math.random() * 900000).toString();
      console.log("Generated OTP:", otp);

      const response = await axios.post(`${url}/admin/save-otp`, {
        admin_contact: formData.admin_contact,
        otp: otp,
      });

      console.log("OTP save response:", response);
      toast.success("OTP has been sent to your email.");
      setOtpSent(true); // Show OTP input field after sending
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong.");
    }
  };

  const handleOtpVerify = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${url}/admin/verify-otp`, {
        admin_contact: formData.admin_contact,
        otp: formData.otp,
      });

      console.log("OTP verification response:", response);
      toast.success("OTP verified successfully. Reset your password now!");
      navigate("/reset-password"); // Redirect to reset password page
    } catch (error) {
      toast.error(error.response?.data?.message || "OTP verification failed.");
    }
  };

  const toggleResetPassword = () => {
    setIsResettingPassword(!isResettingPassword);
    setOtpSent(false);
    setFormData({
      admin_contact: "",
      admin_password: "",
      otp: "",
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 4, mt: 8 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {isResettingPassword ? "Reset Password" : "Admin Sign In"}
          </Typography>
          <Box
            component="form"
            onSubmit={
              isResettingPassword
                ? (otpSent ? handleOtpVerify : handleOtpSend)
                : handleSignIn
            }
            sx={{ mt: 3 }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="admin_contact"
              label="Email or Phone"
              name="admin_contact"
              autoComplete="email"
              value={formData.admin_contact}
              onChange={handleInputChange}
              disabled={otpSent && isResettingPassword} // Disable during OTP input
            />
            {otpSent && isResettingPassword && (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="otp"
                label="Enter OTP"
                name="otp"
                value={formData.otp}
                onChange={handleInputChange}
              />
            )}
            {!isResettingPassword && (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="admin_password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                value={formData.admin_password}
                onChange={handleInputChange}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <Button onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  ),
                }}
              />
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {isResettingPassword
                ? otpSent
                  ? "Verify OTP"
                  : "Send OTP"
                : "Sign In"}
            </Button>
            <Link
              component="button"
              onClick={toggleResetPassword}
              variant="body2"
              sx={{ mt: 2 }}
            >
              {isResettingPassword ? "Back to Sign In" : "Forgot Password?"}
            </Link>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default AuthForm;
